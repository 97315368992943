import './layout.scss'

import { StaticQuery, graphql } from 'gatsby'

import Header from './header'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import React from 'react'

const getFullTitle = (siteTitle, pageTitle) =>
  `${siteTitle}${pageTitle ? ` - ${pageTitle}` : ''}`

const Layout = ({ children, title, description, image, currentProject }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            description
            keywords
            twitter
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={getFullTitle(data.site.siteMetadata.title, title)}
          meta={[
            {
              name: 'description',
              content: description || data.site.siteMetadata.description,
            },
            {
              name: 'keywords',
              content: data.site.siteMetadata.keywords,
            },
            {
              name: 'twitter:card',
              content: 'summary_large_image',
            },
            {
              name: 'twitter:site',
              content: data.site.siteMetadata.twitter,
            },
            {
              name: 'twitter:title',
              content: data.site.siteMetadata.title,
            },
            {
              name: 'twitter:description',
              content: title || data.site.siteMetadata.description,
            },
            {
              name: 'twitter:image',
              content: image ? image.src : '',
            },
            {
              property: 'og:site_name',
              content: data.site.siteMetadata.title,
            },
            {
              property: 'og:title',
              content: title || data.site.siteMetadata.title,
            },
            {
              property: 'og:description',
              content: description || data.site.siteMetadata.description,
            },
            {
              property: 'og:image',
              content: image ? image.src : '',
            },
            {
              property: 'og:image:height',
              content: image ? image.height : '',
            },
            {
              property: 'og:image:width',
              content: image ? image.width : '',
            },
            {
              name: 'apple-mobile-web-app-status-bar-style',
              content: 'black',
            },
          ]}
        >
          <html lang="en" />
        </Helmet>
        <Header
          siteTitle={data.site.siteMetadata.title}
          currentProject={currentProject}
        />
        <main>{children}</main>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  titleParts: PropTypes.object,
  image: PropTypes.object,
}

export default Layout
