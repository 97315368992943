import React from 'react'
import styles from './header.module.scss'
import { StaticQuery, graphql, Link } from 'gatsby'
import Logo from '../images/logo.svg'

const Header = ({ currentProject }) => (
  <StaticQuery
    query={graphql`
      query HeaderQuery {
        site {
          siteMetadata {
            title
            description
          }
        }
        projects: allProject(sort: { fields: [name], order: ASC }) {
          edges {
            node {
              name
              title
              slug
            }
          }
        }
      }
    `}
    render={data => (
      <div className={styles.header}>
        <Link
          className={styles.logoAnchor}
          to={currentProject ? currentProject.slug : '/'}
        >
          <img className={styles.logo} alt="Shah Kashani" src={Logo} />
        </Link>
        <div className={styles.nav}>
          {data.projects.edges.map(edge => {
            return (
              <Link
                key={edge.node.name}
                to={edge.node.slug}
                className={
                  currentProject && currentProject.name === edge.node.name
                    ? styles.currentProject
                    : null
                }
              >
                {edge.node.title}
              </Link>
            )
          })}
        </div>
      </div>
    )}
  />
)

export default Header
